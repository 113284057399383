import React, {useState} from "react"
import Layout from "../components/layout"
import Waitlist from "../components/waitlist"
import Seo from "../components/seo"



const IndexPage = () => {
  const [formSuccess, setFormSuccess] = useState(false);

  return (
    <Layout>
      <Seo title="One Pan Muscle" description="Minimalist muscle building meals." lang="english" meta={[]} />
      <div className='max-w-2xl mx-auto my-40 flex items-center justify-center flex-wrap'>
        <h1 className='basis-full text-center mb-4'>OnePanMuscle.com</h1>
        <p className='basis-full text-center mb-8'>Minimalist muscle building meals.</p>
        <div>
        {formSuccess ? (
          <h3>Thanks!</h3>
        ) : (
          <Waitlist
          logsnagToken={'7531f66b268cf411ff8918b2944cd221'}
          logsnagProject={'dobro'}
          lognsnagChannel={'one-pan-muscle'}
          logsnagEvent={'Ebook Waitlist'}
          setFormSuccess={setFormSuccess}/>
        )}

        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
