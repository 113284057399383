
import { LogSnag } from 'logsnag';
import { Formik } from 'formik';
import React from "react"

const Waitlist = ({ setFormSuccess, logsnagToken, logsnagProject, lognsnagChannel, logsnagEvent }) => {

  return (
    <Formik
      initialValues={{ email: '', password: '' }}
      validateOnChange={false}
      validateOnBlur={false}
      validate={values => {
        const errors = {};
        if (!values.email) {
          errors.email = 'Please use your email address to sign up.';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
          errors.email = 'Please use a valid email address.';
        }
        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        new LogSnag({
          token: logsnagToken,
          project: logsnagProject
        }).publish({
            channel: lognsnagChannel,
            event: logsnagEvent,
            description: `${values.email}`,
            icon: "🎉",
            tags: { email: `${values.email}`},
            notify: true
        }).then(result => {
          setSubmitting(false)
          setFormSuccess(true)
        }).catch(error => setSubmitting(false));
      }}
    >
      {({ values, errors, handleChange, handleSubmit, isSubmitting}) => (
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            name="email"
            placeholder="email@address.com"
            onChange={handleChange}
            value={values.email}
          />
          <button type="submit" disabled={isSubmitting}>
            Submit
          </button>
          <div>
          {errors.email && errors.email}
          </div>
        </form>
      )}
    </Formik>
  )
}
export default Waitlist
